<template>
  <div class="fixed-banner">
    <v-dialog
      v-model="dialogVisible"
      width="550"
    >
      <v-card>
        <v-card-title class="text-h5">
          Pathfinder Version 3.0 is Here!
        </v-card-title>
        <v-card-text class="pb-0">
          <p>
            To ensure a smooth transition, both Pathfinder 2.0 (current version) and 3.0 (new version)
            will be available for a six-month window.
          </p>

          <p>
            During this transition you will need to replace all 2.0 elements with 3.0 elements. Or, rebuild your
            project in Pathfinder 3.0 for the best results.
          </p>

          <p>
            All new projects will start as Pathfinder 3.0.
          </p>

          <p>
            In order to keep Pathfinder 2.0 custom elements, open the existing custom element and
            change the category to a corresponding new 3.0 category.
            The custom element will then move down into the assigned 3.0 element category list.
          </p>
          <div class="text-h6 mt-4 mb-4">
            Key Dates
          </div>
          <ul>
            <li><strong>2.0 &gt; 3.0 Transition Window:</strong> Oct 1, 2024 through March 31, 2025</li>
            <li>
              <strong>After March 31, 2025:</strong> Pathfinder 2.0 element menus will be removed, and 2.0 elements
              in existing projects will no longer be editable (though they can be deleted).
            </li>
          </ul>
          <div class="text-h6 mt-4 mb-4">
            Pathfinder 3.0 is packed with updates, including
          </div>
          <p>
            <ul>
              <li>Expanded Materials Emissions Data Set</li>
              <li>Distance and Transportation Emissions Modifiers</li>
              <li>Protected and Restored Ecosystem Sequestration</li>
              <li>Biodiversity, Water, Cooling, and Equity Impact Tracking</li>
              <li>Advanced Scorecard with Baseline Metrics</li>
              <li>...and much more!</li>
            </ul>
          </p>
          <p>
            Download the
            <a
              href="https://climatepositivedesign.com/wp-content/uploads/2024/09/CPD_Pathfinder-3.0_User-Guide.pdf"
              target="_blank"
              style="text-decoration: underline;"
            >
              Pathfinder 3.0 User Guide
            </a> to learn more.
          </p>
          <p>
            We’re viewing this transition window as a beta testing period and expect some bugs and other issues to occur. Please don’t hesitate to reach out with any
            questions, concerns, or observations during this time by using the
            <a
              href="https://climatepositivedesign.us22.list-manage.com/track/click?u=373c017aa81ffceb33e9e6e9c&id=004b97333c&e=23c2a35fd7"
              target="_blank"
              style="text-decoration: underline;"
            > contact form
            </a>.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="dialogVisible = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-alert
      v-if="alert"
      v-model="alert"
      class="cpd-banner"
      border="left"
      :color="color"
      dark
      dense
      dismissible
    >
      <b>Pathfinder Version 3.0 now available! Don't forget to migrate all
        Pathfinder 2.0 projects by March 31st, 2025. Learn how
        <a
          :class="{inverted: color === 'oynx'}"
          href="#"
          @click.prevent="handleClick"
        >HERE</a>.
      </b>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'FixedBanner',
  props: {
    color: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data () {
    return ({
      alert: null,
      dialogVisible: false
    })
  },
  watch: {
    alert (value) {
      this.$cookies.set(`show-banner-${this.name}`, value)
    }
  },
  created () {
    const currentBannerState = this.$cookies.get(`show-banner-${this.name}`)
    this.alert = currentBannerState !== 'false'
  },
  methods: {
    handleClick () {
      this.dialogVisible = true
    }
  }
}
</script>

<style>
.cpd-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
  width: 100%;
  border-radius: 0 !important;
  text-align: center;
}
div:has(> .cpd-banner) {
  padding-bottom: 100px !important;
}
.fixed-banner .dialog {
  max-width: 550px;
}
.fixed-banner .inverted {
  color: white;
  text-decoration: underline;
}

</style>
